import React from 'react'
import { PICS_URL } from '../../../../lib/App/common/config'
import { Tooltip } from '../../../../App/common/InfoButton/InfoButton'
import Translate from '../../../../lib/App/common/translator/components/translate'

const EmphasizedKategorieLeonardo = props => {
  const option = props.optionen[0]
  const variationen = option.variationen

  const emphasizedCentered = { 'margin': 'auto', 'float': 'unset !important', 'textAlign': 'center' }
  const translateName = <Translate id={'optionen/' + option.optionCode + '/name'}>{option.name}</Translate>
  const translateDesc = <Translate id={'optionen/' + option.optionCode + '/beschreibung'}>{option.beschreibung}</Translate>

  return (
    <div className='bk-options-icons bk-options-types group columns large-12 medium-12 small-12'>
      <div className='large-8 medium-8 small-12' style={emphasizedCentered}>
        <h2 style={{ 'textAlign': 'center' }}>{translateName}</h2>
        <p>{translateDesc}</p>
      </div>
      <div className='large-9 medium-12 small-12' style={emphasizedCentered}>
        <ul className='group'>
          {variationen.map(item => {
            return (
              <EmphasizedVariation key={item.variationId} {...item}
                handleVariationChange={props.handleVariationChange}
                isReadOnly={props.isReadOnly} optionCode={option.optionCode} />
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default EmphasizedKategorieLeonardo

const EmphasizedVariation = props => {
  const { action, disabled } = props.isReadOnly
    ? { action: () => {}, disabled: ' disabled' }
    : { action: () => props.handleVariationChange(props), disabled: '' }

  const tooltip = props.tooltip ? <Tooltip toolTip={props.tooltip} tooltipToLeft outerStyle={{ display: 'inline-block' }} innerStyle={{ display: 'block' }} translateId={'variationen/' + props.variationCode + '/tooltip'} /> : null
  const imagePath = props.selected ? props.imagePath + '_checked' : props.imagePath
  const translateName = <Translate id={'variationen/' + props.variationCode + '/name'}>{props.name}</Translate>
  const translateAufpreis = props.aufpreisBeschreibung ? <Translate id={'variationen/' + props.variationCode + '/aufpreisBeschreibung'}>{props.aufpreisBeschreibung}</Translate> : ''

  return (
    <li className='group'>
      <label className='label' htmlFor={'bk-option-' + props.variationId} onClick={action}>
        <div className='bk-radio-image'>
          <img src={PICS_URL + '/raw/' + imagePath + '.svg'} alt={'IMAGE ' + props.name} />
        </div>
        <input type='radio' name={'bk_option-' + props.variationId} id={'bk-option-' + props.variationId}
          onChange={() => {}} disabled={props.isReadOnly} checked={props.selected} />
        <span>
          <span className={'radio-check' + disabled} />
          {translateName}
          {tooltip}
        </span>
        <div>
          <span className={'costs-additional'}>{translateAufpreis}</span>
        </div>
      </label>
    </li>
  )
}
