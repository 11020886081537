import React from 'react'
import { Link } from 'react-router-dom'

const Unauthorized401 = props => {
  return (
    <section className='group bk-prop-selected'>
      <div className='group row'>
        <div className='group columns large-12 medium-12 small-12' style={{ textAlign: 'center' }}>
          <h1 style={{ textAlign: 'center' }}><i className='fas fa-exclamation-triangle' /> 401 - Zugriff verweigert</h1>
          <p>Bitte melden Sie sich an. <Link style={{ color: '#fff' }} to='/anmelden'>Weiter zum Login</Link></p>
        </div>
      </div>
    </section>
  )
}

export default Unauthorized401
