import React from 'react'
import PropTypes from 'prop-types'
import SearchGroupLabel from '../SearchGroupLabel'
import Columns from '../Columns'
import SearchGroupOption from '../SearchGroupOption'
import SearchGroupUnorderedList, { SearchGroupListEntry } from '../SearchGroupList'
import Slider from 'rc-slider'
import { Tooltip } from '../../../../../App/common/InfoButton/InfoButton'
import Translate from '../../../../../lib/App/common/translator/components/translate'

const SearchRatio = props => {
  const { min, max, step } = props.number

  let ratioValues = []
  for (let i = max; i >= min; i -= step) {
    ratioValues.push(i)
  }

  const ratioOptions = ratioValues.map((value, index) => {
    return (
      <option key={'ratioOption_' + index} value={value}>{value}{props.number.unit}</option>
    )
  })

  let marks = {}
  marks[min] = min + props.number.unit
  marks[max] = max + props.number.unit

  const currentValue = props.current ? props.current : props.number.defaultValue
  const translateTooltip = <Translate id={'BueroList/Search/SearchRatio/tooltip'}>Bestimmt die Anzahl Arbeitsplätze im Verhältnis zur Anzahl der Mitarbeitenden - die Auswahl ist nur bei der Arbeitsweise "Activity Based Working" möglich.</Translate>

  return (
    <SearchGroupOption optionClass='bk-search-group-option-ratio' disabled={props.activeOption === 'blbz'}>
      <SearchGroupLabel title={'Desk Share Ratio'} desc={''} translateId={props.translateId} />

      <Columns large={7} medium={7} small={12}>
        <SearchGroupUnorderedList>
          <SearchGroupListEntry>
            <Slider
              dots
              step={step}
              min={min}
              max={max}
              defaultValue={props.number.defaultValue}
              value={currentValue}
              onChange={props.changed}
              marks={marks}
              disabled={props.activeOption === 'blbz'}
              style={{ backgroundColor: 'inherit' }}
            />
          </SearchGroupListEntry>
          <SearchGroupListEntry>
            <label className='label' htmlFor='bk-search-desk-share'>
              <div className='custom-select'>
                <select
                  onChange={(event) => { props.changed(event.target.value) }}
                  name='bk_search_desk_share'
                  id='bk-search-desk-share'
                  size='1'
                  value={currentValue}
                  disabled={props.activeOption === 'blbz'}>
                  {ratioOptions}
                </select>
                <Tooltip toolTip={translateTooltip} />
              </div>
            </label>
          </SearchGroupListEntry>
        </SearchGroupUnorderedList>
      </Columns>
    </SearchGroupOption>
  )
}

SearchRatio.propTypes = {
  number: PropTypes.object.isRequired,
  changed: PropTypes.func.isRequired,
  activeOption: PropTypes.string
}

export default SearchRatio
