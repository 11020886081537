import { combineReducers } from 'redux'
import wohnungListReducer from '../BueroList/reducer'
import wohnungsgrundrissReducer from '../lib/App/common/wohnungsgrundriss/reducer'
import konfigurationReducer from '../lib/App/common/konfigurator/reducer'
import zusammenfassungReducer from '../lib/Zusammenfassung/reducer'
import userReducer from '../lib/User/reducer'
import konfigurationListReducer from '../lib/KonfigurationList/reducer'
import exportReducer from '../lib/Export/reducer'
import staticVariationsReducer from '../lib/App/common/optionen/reducer'
import translatorReducer from '../lib/App/common/translator/reducer'
import { localizeReducer } from 'react-localize-redux'

const rootReducer = combineReducers({
  wohnungList: wohnungListReducer,
  wohnungsgrundriss: wohnungsgrundrissReducer,
  konfiguration: konfigurationReducer,
  translator: translatorReducer,
  zusammenfassung: zusammenfassungReducer,
  user: userReducer,
  konfigurationList: konfigurationListReducer,
  export: exportReducer,
  staticVariations: staticVariationsReducer,
  localize: localizeReducer
})

export default rootReducer
