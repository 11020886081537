import React from 'react'
import { PICS_URL } from '../../lib/App/common/config'
import './Rendering.css'

const Rendering = () => {
  const renderingSrc = PICS_URL + `/880w/auxiliary/zusammenfassung.jpg`
  const rendering = <img key={'zusammenfassung-rendering'} src={renderingSrc} alt='rendering' />

  return (
    <section className='group bk-prop-selected rendering-zusammenfassung' style={{ padding: 0 }}>
      <div className='group row'>
        {rendering}
      </div>
    </section>
  )
}

export default Rendering
