import React from 'react'

const SearchGroup = props => {
  return (
    <fieldset className={(props.groupClass) + ' group row'}>
      {props.children}
    </fieldset>
  )
}

export default SearchGroup
