import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { KonfigurationList, mapStateToProps, mapDispatchToProps }
  from '../lib/KonfigurationList'
import SubHeader from '../App/common/Header/SubHeader'
import Table from './components/Table'
import Filter from './components/Filter'

export class MaklerKonfigurationListLeonardo extends KonfigurationList {
  render () {
    const isMakler = this.props.isMaklerList
    return (
      <Fragment>
        <SubHeader
          title='Kunden&shy;konfigu&shy;rationen' description='Verwalten Sie Ihre Kundenkonfigurationen...'
          translateId={'Kundenkonfigurationen/'}
        />
        <section className='group bk-configurations'>
          <div className='group row'>
            <Filter isMakler={isMakler} />
            <div className='columns large-12 medium-12 small-12'>
              <Table actions={this.props.actions} rows={this.props.filteredList}
                isMakler={isMakler} report={this.props.report} />
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

MaklerKonfigurationListLeonardo.defaultProps = { isMaklerList: true }

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MaklerKonfigurationListLeonardo))
