import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducer from './rootReducer'
import rootSaga from './rootSaga'

import * as authActionType from '../lib/App/common/auth/actionType'

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware()

  let composeEnhancers = compose
  if (process.env.NODE_ENV === 'development') {
    // Chrome Redux extension: http://extension.remotedev.io/
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  }

  const middlewares = [sagaMiddleware]

  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )
  sagaMiddleware.run(rootSaga)

  // dispatch access
  store.dispatch({ type: authActionType.FETCH })

  return store
}
