import { take, put, fork, takeLatest, call, select, delay } from 'redux-saga/effects'
import provider from '../../../lib/App/common/konfigurator/provider'
import * as actionType from '../../../lib/App/common/konfigurator/actionType'
import { bootstrap, fetch, fetchVariationList, setResponse, fetchReport,
  selectVariation, selectNumberVariation, selectTextVariation,
  updateVariation, updateNumberVariation, updateTextVariation,
  updateKonfigurationFrontendProperty, updateFrontendProperty,
  updateWohnungsgrundriss, fetchFromShareToken
} from '../../../lib/App/common/konfigurator/saga'
import * as errorActionType from '../../../lib/App/common/error/actionType'

import React from 'react'
import { toast } from 'react-toastify'
import Toaster from '../Toaster/Toaster'

export function * saveKonfigurationVisualisation () {
  const user = yield select(state => state.user.current)
  if (user !== null && user !== undefined) {
    yield call(showToast)
  }
}

export function * fetchNew (api) {
  while (true) {
    try {
      const action = yield take(actionType.FETCH_NEW)
      const user = yield select(state => state.user.current)
      const response = yield call(
        api.createKonfiguration, {
          wohnungsgrundrissId: action.payload.wohnungsgrundrissId,
          variationIds: action.payload.variationIds || [],
          numbers: action.payload.numbers || []
        })
      yield call(setResponse, response, user, action.payload.nextPage)
    } catch (error) {
      yield put({ type: actionType.SET_KONFIGURATION_V, payload: {} })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export const sagas = [
  fork(bootstrap),
  fork(fetch, provider),
  fork(fetchNew, provider),
  fork(fetchVariationList, provider),
  fork(fetchReport, provider),
  fork(selectVariation),
  fork(selectNumberVariation),
  fork(selectTextVariation),
  fork(updateVariation, provider),
  fork(updateNumberVariation, provider),
  fork(updateTextVariation, provider),
  fork(updateKonfigurationFrontendProperty, provider),
  fork(updateFrontendProperty, provider),
  fork(updateWohnungsgrundriss, provider),
  fork(fetchFromShareToken, provider),
  takeLatest(actionType.SAVE_KONFIGURATION, saveKonfigurationVisualisation)
]

let toastrId = null

export function * showToast () {
  if (!toast.isActive(toastrId)) {
    yield delay(350)
    toastrId = yield call(toast.info, <Toaster />, {
      autoClose: 2000,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER })
  } else {
    yield call(toast.update, toastrId)
  }
}
