import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Aktivierung, mapStateToProps, mapDispatchToProps }
  from '../../lib/User/components/Aktivierung'
import SubHeader from '../../App/common/Header/SubHeader'
import Translate from '../../lib/App/common/translator/components/translate'

export class AktivierungLeonardo extends Aktivierung {
  getError () {
    const translateError = <Translate id={'aktivierung/error'}>Ein Fehler ist aufgetreten...</Translate>

    return this.props.feedback.activation === false
      ? <div className='error'><i className='fas fa-bell' /> {translateError}</div>
      : null
  }

  getMessage () {
    const translateLoad = <Translate id={'aktivierung/laedt'}>Einen Augenblick Bitte, Ihre Aktivierung erfolgt...</Translate>
    const translateSucc = <Translate id={'aktivierung/erfolgreich'}>Sie haben Ihr Benutzerkonto erfolgreich aktiviert und können nun Konfigurationen abspeichern und später wieder aufrufen.</Translate>
    const translateRedirect = <Translate id={'aktivierung/weiterleitung'}>Die Aktivierung war erfolgreich Sie werden in wenigen Sekunden weitergeleitet.</Translate>
    const translateContinue = <Translate id={'aktivierung/weiter'}>Weiter</Translate>

    return this.props.user === null
      ? <div><span>{translateLoad}</span></div>
      : (
        <Fragment>
          <div className='success'>
            <i className='far fa-check-circle' /> {translateSucc}<br />
          </div>
          <div>{translateRedirect} <Link to='/anmelden' style={{ color: '#fff' }}>{translateContinue}</Link></div>
        </Fragment>
      )
  }

  render () {
    const message = this.getMessage()
    const error = this.getError()

    return (
      <Fragment>
        <SubHeader title='Aktivierung' translateId={'aktivierung/'} />
        <section className='group bk-first'>
          <form className='group' noValidate>
            <fieldset className='group row'>
              {message}
              {error}
            </fieldset>
          </form>
        </section>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AktivierungLeonardo)
