import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Error from '../App/common/Error/error'
import Unauthorized401 from '../App/common/Error/components/Unauthorized401'
import Forbidden403 from '../App/common/Error/components/Forbidden403'
import NotFound404 from '../App/common/Error/components/NotFound404'
import Aktivierung from '../User/components/Aktivierung'
import Anmeldung from '../User/components/Anmeldung'
import Registrierung from '../User/components/Registrierung'
import PasswortWiederherstellung from '../User/components/PasswortWiederherstellung'
import PasswortZuruecksetzung from '../User/components/PasswortZuruecksetzung'
import Profil from '../User/components/Profil'
import KonfigurationList from '../KonfigurationList/KonfigurationList'
import MaklerKonfigurationList from '../KonfigurationList/MaklerKonfigurationList'
import BueroList from './../BueroList/BueroList'
import KonfiguratorPage from '../KonfiguratorPage/KonfiguratorPage'
import Zusammenfassung from '../Zusammenfassung/Zusammenfassung'
import KonfigurationStart from '../KonfigurationStart/KonfigurationStart'

const Konfiguration = props => (
  <KonfiguratorPage {...props} title='Konfiguration'
    step={2}
    kategorieClasses={['konfiguration']}
    nextLink={konfigurationVId => '/konfigurator/' + konfigurationVId + '/zusatzoptionen'}
    nextTitle={'Konditionen'}
    previousTitle={'Konfiguration verlassen'}
    previousLink={() => '/'} />
)

const Zusatzoptionen = props => (
  <KonfiguratorPage {...props} title='Zusatzoptionen'
    step={3}
    kategorieClasses={['zusatzoptionen']}
    nextLink={konfigurationVId => '/konfigurator/' + konfigurationVId + '/zusammenfassung'}
    nextTitle={'Zusammenfassung'}
    previousTitle={'Zurück'}
    previousLink={konfigurationVId => '/konfigurator/' + konfigurationVId + '/konfiguration'} />
)

const routes = (
  <Switch>
    <Route exact path='/' component={() => {
      window.location.href = process.env.REACT_APP_EXTERNAL_HOMEPAGE_URL
      return null
    }} />
    <Route exact path='/konfigurator/:bueroId' component={KonfigurationStart} />
    <Route exact path='/konfigurator/:konfigurationVId/konfiguration' render={Konfiguration} />
    <Route exact path='/konfigurator/:konfigurationVId/zusatzoptionen' render={Zusatzoptionen} />
    <Route exact path='/konfigurator/:konfigurationVId/zusammenfassung' component={Zusammenfassung} />
    <Route exact path='/aktivieren/:activationToken' component={Aktivierung} />
    <Route exact path='/anmelden' component={Anmeldung} />
    <Route exact path='/registrieren' component={Registrierung} />
    <Route exact path='/passwort/wiederherstellen' component={PasswortWiederherstellung} />
    <Route exact path='/passwort/zuruecksetzen/:passwordResetToken'
      component={PasswortZuruecksetzung} />
    <Route exact path='/profil' component={Profil} />
    <Route exact path='/konfigurationen' component={KonfigurationList} />
    <Route exact path='/konfigurationen/makler' component={MaklerKonfigurationList} />
    <Route exact path='/fehler' component={Error} />
    <Route exact path='/fehler/401' component={Unauthorized401} />
    <Route exact path='/fehler/403' component={Forbidden403} />
    <Route exact path='/fehler/404' component={NotFound404} />
    <Route component={NotFound404} />
  </Switch>
)

export default routes
