import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { KonfigurationList, mapStateToProps, mapDispatchToProps }
  from '../lib/KonfigurationList'
import SubHeader from '../App/common/Header/SubHeader'
import Table from './components/Table'

export class KonfigurationListLeonardo extends KonfigurationList {
  render () {
    return (
      <Fragment>
        <SubHeader
          title='Konfigurationen' description='Hier können Sie Ihre gespei­cherten Konfi­gu­ra­tionen selek­tieren und verwalten...'
          translateId='Konfigurationen/'
        />
        <section className='group bk-configurations'>
          <div className='group row'>
            <div className='columns large-12 medium-12 small-12'>
              <Table actions={this.props.actions} rows={this.props.filteredList}
                report={this.props.report} isMakler={false} />
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KonfigurationListLeonardo))
