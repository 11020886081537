import React from 'react'

const Forbidden403 = props => {
  return (
    <section className='group bk-prop-selected'>
      <div className='group row'>
        <div className='group columns large-12 medium-12 small-12' style={{ textAlign: 'center' }}>
          <h1 style={{ textAlign: 'center' }}><i className='fas fa-exclamation-triangle' /> 403 - Zugriff verweigert</h1>
          <p>Sie besitzen nicht die Berechtigung diese Seite anzuschauen.</p>
        </div>
      </div>
    </section>
  )
}

export default Forbidden403
