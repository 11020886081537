import React from 'react'
import { CurrencyFormat, NumberFormat } from '../../../../lib/App/common/ValueFormatter'
import Translate from '../../../../lib/App/common/translator/components/translate'

const ResultRow = props => {
  const etagen = props.buero.etageNummer.length > 1 ? Math.min(...props.buero.etageNummer) + '. - ' + Math.max(...props.buero.etageNummer) : parseInt(props.buero.etageNummer[0])
  const translateEtageType = props.buero.etageNummer.length >= 1 && etagen !== 0 ?
      <Translate id='BasicHighlights/etage-type/og' data={{ floor: etagen }}>{'${floor}. OG'}</Translate> :
      <Translate id='BasicHighlights/etage-type/eg'>EG</Translate>

  return (
    <tr
      onClick={props.rowClicked}
      className={'bk-result-row ' + (props.active ? 'active' : '')}
      onMouseEnter={() => { props.updateIsometrie(props.buero.einheitCodes) }}
      onMouseLeave={() => { props.updateIsometrie(props.unitsBeforeHover) }}
    >
      <td />
      <td>{translateEtageType}</td>
      <td><NumberFormat value={props.buero.flaeche} /> m<sup>2</sup></td>
      <td className='numeric'><CurrencyFormat value={props.buero.preis} currency='CHF' /></td>
      <td className='numeric'><CurrencyFormat value={props.buero.jahresmietzins} currency='CHF' /></td>
    </tr>
  )
}

export default ResultRow
