import React, { Component, Fragment } from 'react'
import KonfiguratorHighlights from './components/KonfiguratorHighlights'
import { Route, withRouter, Link } from 'react-router-dom'
import ZusatzoptionenHighlights from './components/ZusatzoptionenHighlights'
import BasicHightlights from './components/BasicHighlights'
import Translate from '../../../lib/App/common/translator/components/translate'
import { connect } from 'react-redux'

class HighlightsLeonardo extends Component {
  render () {
    const translateKonf = <Translate id={'Highlights/ihre-konfiguration'}>Ihre Konfiguration</Translate>
    const translateSaveIntro = <Translate id={'Highlights/save-intro'}>Speichern Sie diese Konfiguration und wir werden uns mit Ihnen in Verbindung setzen.</Translate>

    return (
      <Fragment>
        <Route exact path='/konfigurator/:konfigurationVId/konfiguration' render={
          () => <KonfiguratorPageWrapper wohnungsgrundriss={this.props.wohnungsgrundriss} konfigurationV={this.props.konfigurationV} translateKonf={translateKonf} />} />
        <Route exact path='/konfigurator/:konfigurationVId/zusatzoptionen' render={
          () => <ZusatzoptionenPageWrapper wohnungsgrundriss={this.props.wohnungsgrundriss} konfigurationV={this.props.konfigurationV} translateKonf={translateKonf} />} />
        <Route exact path='/konfigurator/:konfigurationVId/zusammenfassung' render={
          () => <ZusammenfassungPageWrapper wohnungsgrundriss={this.props.wohnungsgrundriss} konfigurationV={this.props.konfigurationV} translateKonf={translateKonf} translateSaveIntro={translateSaveIntro} />} />
      </Fragment>
    )
  }
}

const KonfiguratorPageWrapper = props => {
  return (
    <Fragment>
      <section className='group bk-prop-selected'>
        <div className='group row'>
          <h2>{props.translateKonf}</h2>
          <div className='group columns large-6 medium-6 small-12'>
            <BasicHightlights konfigurationV={props.konfigurationV} wohnungsgrundriss={props.wohnungsgrundriss} />
          </div>
          <div className='group columns large-6 medium-6 small-12'>
            <table>
              <tbody>
                <KonfiguratorHighlights konfigurationV={props.konfigurationV} />
                <ZusatzoptionenHighlights konfigurationV={props.konfigurationV} />
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

const ZusatzoptionenPageWrapper = props => {
  return (
    <section className='group bk-prop-selected'>
      <div className='group row'>
        <h2>{props.translateKonf}</h2>
        <div className='group columns large-6 medium-6 small-12'>
          <BasicHightlights konfigurationV={props.konfigurationV} wohnungsgrundriss={props.wohnungsgrundriss} />
        </div>
        <div className='group columns large-6 medium-6 small-12'>
          <table>
            <tbody>
              <KonfiguratorHighlights konfigurationV={props.konfigurationV} />
              <ZusatzoptionenHighlights konfigurationV={props.konfigurationV} />
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

const ZusammenfassungPageWrapper = props => {
  return (
    <Fragment>
      <div className='bk-options-summary group columns large-12 medium-12 small-12' style={{ borderTop: 'none' }}>
        {props.translateSaveIntro}
      </div>
      <div className='bk-options-summary group columns large-12 medium-12 small-12' style={{ borderTop: 'none' }}>
        <h2>{props.translateKonf}</h2>
        <div className='group columns large-6 medium-6 small-12'>
          <BasicHightlights konfigurationV={props.konfigurationV} wohnungsgrundriss={props.wohnungsgrundriss} />
        </div>
        <div className='group columns large-6 medium-6 small-12'>
          <table>
            <tbody>
              <KonfiguratorHighlights konfigurationV={props.konfigurationV} />
              <ZusatzoptionenHighlights konfigurationV={props.konfigurationV} />
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

function mapStateToProps (state) {
  return {
    wohnungsgrundriss: state.wohnungsgrundriss.current,
    konfigurationV: state.konfiguration.currentV
  }
}

export default withRouter(connect(mapStateToProps)(HighlightsLeonardo))
