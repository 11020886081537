import React, { Fragment } from 'react'
import VariationenSwitch, { BooleanVariation, SliderVariation, TextVariation } from './Variationen'
import Translate from '../../../../lib/App/common/translator/components/translate'

export function optionenSwitch (option, components, OwnOptionComponent) {
  const { BooleanOption, TextOption } = components
  if (option.classes.indexOf('boolean') > -1) {
    return BooleanOption
  } else if (~option.classes.indexOf('text')) {
    return TextOption
  } else if (~option.classes.indexOf('info')) {
    return InfoOption
  } else if (~option.classes.indexOf('number')) {
    return NumberOption
  } else if (OwnOptionComponent !== undefined) {
    return OwnOptionComponent
  } else {
    return VariationenSwitch
  }
}

const OptionenSwitch = props => (
  <Fragment>
    {props.optionen.map(option => {
      const OptionComponent =
        props.switches.optionenSwitch(option, props.components, props.ownOptionComponent)
      return <OptionComponent key={option.optionId} {...option}
        kategorieId={props.kategorieId}
        switches={props.switches}
        components={props.components}
        isReadOnly={props.isReadOnly}
        handleVariationChange={props.handleVariationChange}
        handleFrontendPropertyChange={props.handleFrontendPropertyChange} />
    })}
  </Fragment>
)

export default OptionenSwitch

export const Option = props => {
  const translateName = <Translate id={'optionen/' + props.optionCode + '/name'}>{props.name}</Translate>
  const translateDesc = props.beschreibung ? <Translate id={'optionen/' + props.optionCode + '/beschreibung'}>{props.beschreibung}</Translate> : ''

  return (
    <div key={props.optionId} className='bk-options-thumbs group columns large-12 medium-12 small-12' style={props.style}>
      <div className='columns large-12 medium-12 small-12'>
        <h2>{translateName}</h2>
        <p>{translateDesc}</p>
      </div>
      <div className='columns large-12 medium-12 small-12'>
        <VariationenSwitch {...props}
          kategorieId={props.kategorieId}
          switches={props.switches}
          components={props.components}
          isReadOnly={props.isReadOnly}
          handleVariationChange={props.handleVariationChange}
          handleFrontendPropertyChange={props.handleFrontendPropertyChange} />
      </div>
    </div>
  )
}

export const BooleanOption = props => {
  const variationen = props.variationen || []
  const translateName = <Translate id={'optionen/' + props.optionCode + '/name'}>{props.name}</Translate>
  const translateDesc = props.beschreibung ? <Translate id={'optionen/' + props.optionCode + '/beschreibung'}>{props.beschreibung}</Translate> : ''

  return (
    <div className='bk-options-radios group columns large-12 medium-12 small-12' style={props.style}>
      <div className='columns large-12 medium-12 small-12'>
        <h2>{translateName}</h2>
        <p>{translateDesc}</p>
        <ul className='group'>
          {variationen.map(item => <BooleanVariation key={item.variationId} {...props} {...item} />)}
        </ul>
      </div>
    </div>
  )
}

export const InfoOption = props => {
  const variation = props.variationen.length > 0 ? props.variationen[0] : {}
  const translateName = <Translate id={'optionen/' + props.optionCode + '/name'}>{props.name}</Translate>
  const translateDesc = props.beschreibung ? <Translate id={'optionen/' + props.optionCode + '/beschreibung'}>{props.beschreibung}</Translate> : ''

  return (
    <div key={props.optionId} className='bk-options-thumbs group columns large-12 medium-12 small-12' style={props.style}>
      <div className='columns large-5 medium-5 small-12'>
        <h2>{translateName}</h2>
        <p>{translateDesc}</p>
      </div>
      <div className='columns large-7 medium-7 small-12'>
        <h3>{variation.name || ''}</h3>
        <p>{variation.beschreibung || ''}</p>
      </div>
    </div>
  )
}

export const TextOption = props => {
  const text = props.text || {}
  const translateName = <Translate id={'optionen/' + props.optionCode + '/name'}>{props.name}</Translate>
  const translateDesc = props.beschreibung ? <Translate id={'optionen/' + props.optionCode + '/beschreibung'}>{props.beschreibung}</Translate> : ''

  return (
    <div className='bk-options-radios group columns large-12 medium-12 small-12' style={props.style}>
      <div className='columns large-5 medium-5 small-12'>
        <h2>{translateName}</h2>
        <p>{translateDesc}</p>
      </div>
      <div className='columns large-7 medium-7 small-12'>
        <TextVariation {...props} {...text} />
      </div>
    </div>
  )
}

export const NumberOption = props => {
  const number = props.number || {}
  const translateName = <Translate id={'optionen/' + props.optionCode + '/name'}>{props.name}</Translate>
  const translateDesc = props.beschreibung ? <Translate id={'optionen/' + props.optionCode + '/beschreibung'}>{props.beschreibung}</Translate> : ''

  return (
    <div className='bk-options-radios group columns large-12 medium-12 small-12' style={props.style}>
      <div className='columns large-5 medium-5 small-12'>
        <h2>{translateName}</h2>
        <p>{translateDesc}</p>
      </div>
      <div className='columns large-7 medium-7 small-12'>
        <SliderVariation {...props} {...number} />
      </div>
    </div>
  )
}
