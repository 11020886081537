import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { PasswortZuruecksetzungForm_, mapStateToProps, mapDispatchToProps }
  from '../../lib/User/components/PasswortZuruecksetzung'
import SubHeader from '../../App/common/Header/SubHeader'
import { InputLeonardo } from '../../App/common/Form/Form'
import Translate from '../../lib/App/common/translator/components/translate'

const PasswortZuruecksetzungLeonardo = props => {
  return (
    <Fragment>
      <SubHeader title='Passwort vergessen?'
        description='Sie können nun ein neues Passwort setzen.'
        translateId={'PasswortZuruecksetzung/'} />
      <section className='group bk-first'>
        <PasswortZuruecksetzungFormLeonardo match={props.match} history={props.history} />
      </section>
    </Fragment>
  )
}

export default PasswortZuruecksetzungLeonardo

export class PasswortZuruecksetzungFormLeonardo_ extends PasswortZuruecksetzungForm_ {
  componentWillReceiveProps (nextProps) {
    if (nextProps.user !== null) {
      this.props.history.push('/konfigurationen')
    }
  }

  getError () {
    const translate = <Translate id='PasswortZuruecksetzung/error'>Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben...</Translate>

    return this.props.feedback.passwordReset === false
      ? <div className='error'><i className='fas fa-bell' /> {translate}</div>
      : null
  }

  getForm () {
    return (
      <div className='columns large-12 medium-12 small-12'>
        <div className='group'>
          <ol>
            <InputLeonardo
              errorClass='error'
              isValid={isValid => this.updateIsValid('password', isValid)}
              getValue={value => this.updateValue('password', value)}
              showError={this.state.form.showError}
              id='passwordResetPassword'
              displayName='Neues Passwort'
              type='password'
              title='Das Passwort muss mindestens 8 Zeichen enthalten und aus Gross-, Kleinbuchstaben und Zahlen bestehen'
              pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'
              translateId={'PasswortZuruecksetzung/'}
              required />
            <InputLeonardo
              errorClass='error'
              isValid={isValid => this.updateIsValid('passwordConfirm', isValid)}
              getValue={value => this.updateValue('passwordConfirm', value)}
              showError={this.state.form.showError}
              id='passwordResetPasswordConfirm'
              displayName='Neues Passwort bestätigen'
              type='password'
              title='Das Passwort stimmt nicht überein'
              translateId={'PasswortZuruecksetzung/'}
              pattern={'^' + this.patternValueEscape(this.state.form.values.password) + '$'}
              required />
            <li className='bk-submit'><button className='btn btn-inverted' onClick={this.handleSubmit}><Translate id={'PasswortZuruecksetzung/abschicken'}>Anmelden</Translate></button></li>
          </ol>
        </div>
      </div>
    )
  }

  render () {
    const error = this.getError()
    const form = this.getForm()

    return (
      <form className='group' noValidate>
        <fieldset className='group row'>
          {error}
          {form}
        </fieldset>
      </form>
    )
  }
}

const PasswortZuruecksetzungFormLeonardo =
  connect(mapStateToProps, mapDispatchToProps)(PasswortZuruecksetzungFormLeonardo_)
