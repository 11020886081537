import React, { Fragment, Component } from 'react'
import { Row } from '../../lib/KonfigurationList/components/Table'
import KonfigurationName from '../../App/common/KonfigurationName/KonfigurationName'
import { ConfirmModal } from '../../App/common/Modal/Modal'
import Translate from '../../lib/App/common/translator/components/translate'

import editIconSrc from '../../style/assets/site/pics/edit.svg'
import copyIconSrc from '../../style/assets/site/pics/copy.svg'
import searchIconSrc from '../../style/assets/site/pics/search-solid.svg'
import spinnerIconSrc from '../../style/assets/site/pics/spinner-solid.svg'
import handshakeIconSrc from '../../style/assets/site/pics/handshake-solid.svg'
import unlockIconSrc from '../../style/assets/site/pics/unlock-solid.svg'
import downloadIconSrc from '../../style/assets/site/pics/file-pdf.svg'
import removeIconSrc from '../../style/assets/site/pics/times-circle.svg'

export class TableLeonardo extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      toggleRemoveModal: false,
      removeModalActiveKonfigurationId: undefined,
      removeModalActiveKonfigurationName: undefined,
      toggleVermietetModal: false,
      vermietetModalActiveKonfigurationId: undefined,
      vermietetModalActiveKonfigurationName: undefined,
      toggleFreiModal: false,
      freiModalActiveKonfigurationId: undefined,
      freiModalActiveKonfigurationName: undefined
    }

    this.toggleRemoveModal = this.toggleRemoveModal.bind(this)
    this.removeKonfiguration = this.removeKonfiguration.bind(this)
    this.setKonfigurationStatusVermietet = this.setKonfigurationStatusVermietet.bind(this)
    this.setKonfigurationStatusFrei = this.setKonfigurationStatusFrei.bind(this)
    this.toggleVermietetModal = this.toggleVermietetModal.bind(this)
    this.toggleFreiModal = this.toggleFreiModal.bind(this)
    this.finalizeKonfiguration = this.finalizeKonfiguration.bind(this)
  }

  toggleRemoveModal (konfigurationId, konfigurationName) {
    this.setState(state => ({
      toggleRemoveModal: !state.toggleRemoveModal,
      removeModalActiveKonfigurationId: konfigurationId,
      removeModalActiveKonfigurationName: konfigurationName
    }))
  }

  removeKonfiguration () {
    this.toggleRemoveModal()
    this.props.actions.remove(this.state.removeModalActiveKonfigurationId)
  }

  setKonfigurationStatusVermietet () {
    this.toggleVermietetModal()
    this.props.actions.updateStatus(this.state.vermietetModalActiveKonfigurationId, 'vermietet')
  }

  setKonfigurationStatusFrei () {
    this.toggleFreiModal()
    this.props.actions.updateStatus(this.state.freiModalActiveKonfigurationId, 'frei')
  }

  toggleVermietetModal (konfigurationId, konfigurationName) {
    this.setState(state => ({
      toggleVermietetModal: !state.toggleVermietetModal,
      vermietetModalActiveKonfigurationId: konfigurationId,
      vermietetModalActiveKonfigurationName: konfigurationName
    }))
  }

  toggleFreiModal (konfigurationId, konfigurationName) {
    this.setState(state => ({
      toggleFreiModal: !state.toggleFreiModal,
      freiModalActiveKonfigurationId: konfigurationId,
      freiModalActiveKonfigurationName: konfigurationName
    }))
  }

  finalizeKonfiguration () {
    this.toggleFinalizeModal()
    this.props.actions.updateStatus(this.state.finalizeModalActiveKonfigurationId, 'finalisieren')
  }

  getFirstNameColumn () {
    return this.props.isMakler ? <th><Translate id='kundenkonfigurationen/vorname'>Vorname</Translate></th> : null
  }

  getLastNameColumn () {
    return this.props.isMakler ? <th><Translate id='kundenkonfigurationen/nachname'>Nachname</Translate></th> : null
  }

  render () {
    const rows = this.props.rows || []
    const firstNameColumn = this.getFirstNameColumn()
    const lastNameColumn = this.getLastNameColumn()
    return (
      <Fragment>
        <table className='table bk-configurations-table'>
          <thead>
            <tr>
              <th><Translate id='konfigurationen/id'>ID</Translate></th>
              <th><Translate id='konfigurationen/name'>Name Konfiguration</Translate></th>
              {firstNameColumn}
              {lastNameColumn}
              <th><Translate id='konfigurationen/einheiten'>Einheiten</Translate></th>
              <th><Translate id='konfigurationen/status'>Status</Translate></th>
              <th><Translate id='konfigurationen/aktionen'>Aktionen</Translate></th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row =>
              <RowLeonardo key={row.konfigurationId} {...row}
                toggleRemoveModal={this.toggleRemoveModal}
                toggleVermietetModal={this.toggleVermietetModal}
                toggleFreiModal={this.toggleFreiModal}
                report={this.props.report}
                isMakler={this.props.isMakler}
                actions={this.props.actions}
              />)}
            {rows.length === 0 ? <NoEntries isMakler={this.props.isMakler} /> : null}
          </tbody>
        </table>
        <ConfirmModal
          isActive={this.state.toggleRemoveModal}
          toggleModal={this.toggleRemoveModal}
          confirm={this.removeKonfiguration}
          title={'Löschen'}
          name={this.state.removeModalActiveKonfigurationName}
          description={'Möchten Sie die Konfiguration ${name} wirklich löschen?'}
          buttonText={'Löschen'} translateId={'modal/loeschen/'} />
        <ConfirmModal
          isActive={this.state.toggleVermietetModal}
          toggleModal={this.toggleVermietetModal}
          confirm={this.setKonfigurationStatusVermietet}
          title={'Auf vermietet setzen?'}
          name={this.state.vermietetModalActiveKonfigurationName}
          description={'Möchten Sie die Konfiguration ${name} wirklich auf vermietet setzen?'}
          buttonText={'Bestätigen'} translateId={'modal/vermietet-setzen/'} />
        <ConfirmModal
          isActive={this.state.toggleFreiModal}
          toggleModal={this.toggleFreiModal}
          confirm={this.setKonfigurationStatusFrei}
          title={'Auf frei setzen?'}
          name={this.state.freiModalActiveKonfigurationName}
          description={'Möchten Sie die Konfiguration ${name} wirklich wieder auf frei setzen?'}
          buttonText={'Bestätigen'} translateId={'modal/frei-setzen/'} />
      </Fragment>
    )
  }
}

export default TableLeonardo

export const NoEntries = props => {
  const translateNoEntries = <Translate id='Konfigurationen/keine-eintraege'>Keine Konfi&shy;gu&shy;ra&shy;tionen gefunden...</Translate>
  const cols = props.isMakler ? 8 : 6
  return (
    <tr className='bk-configuration-empty'>
      <td colSpan={cols}>{translateNoEntries}</td>
    </tr>
  )
}

export class RowLeonardo extends Row {
  isVermietet () {
    return this.props.status === 'vermietet'
  }

  getEditAction (isVermietet) {
    return !(this.props.ownStatus && isVermietet)
      ? (
        <li>
          <a onClick={() => this.props.actions.goto(this.props.konfigurationId)}>
            <Translate id='konfigurationen/aktion/editieren' data={{ 'editieren': 'editieren', 'editIconSrc': editIconSrc}}>
              <img title={'${editieren}'} src={editIconSrc} alt={'${editieren}'} width='23' />
            </Translate>
          </a>
        </li>
      ) : (
        <li>
          <a onClick={() => this.props.actions.gotoReadOnly(this.props.konfigurationId)}>
            <Translate id='konfigurationen/aktion/anzeigen' data={{ 'anzeigen': 'anzeigen', 'searchIconSrc': searchIconSrc }}>
              <img title={'${anzeigen}'} src={searchIconSrc} alt={'${anzeigen}'} width='23' />
            </Translate>
          </a>
        </li>
      )
  }

  getCopyAction (isFree, isVermietet) {
    return isFree || isVermietet
      ? (
        <li>
          <a onClick={() => this.props.actions.copy(this.props.konfigurationId)}>
            <Translate id='konfigurationen/aktion/kopieren' data={{ 'kopieren': 'kopieren', 'copyIconSrc': copyIconSrc }}>
              <img title={'${kopieren}'} src={copyIconSrc} alt={'${kopieren}'} width='18' />
            </Translate>
          </a>
        </li>
      ) : null
  }

  getDownloadAction (reportStatus) {
    const downloadAction = reportStatus.generatePdf &&
      reportStatus.konfiguration.konfigurationId === this.props.konfigurationId ? (
        <li>
          <span>
            <img title='pdf loading' src={spinnerIconSrc} alt={'pdf loading...'} width='16' />
          </span>
        </li>
      ) : (
        <li>
          <a onClick={() => this.props.actions.fetchReport(this.props.konfigurationId, this.props.name)}>
            <Translate id='konfigurationen/aktion/pdf-herunterladen' data={{ 'pdf-herunterladen': 'pdf herunterladen', 'downloadIconSrc': downloadIconSrc}}>
              <img title={'${pdf-herunterladen}'} src={downloadIconSrc} alt={'${pdf-herunterladen}'} width='16' />
            </Translate>
          </a>
        </li>
      )

    return downloadAction
  }

  getRemoveAction (isFree) {
    return isFree || !this.props.ownStatus
      ? (
        <li>
          <a onClick={() => this.props.toggleRemoveModal(this.props.konfigurationId, this.props.name)}>
            <Translate id='konfigurationen/aktion/loeschen' data={{ 'loeschen': 'loeschen', 'removeIconSrc': removeIconSrc }}>
              <img title={'${loeschen}'} src={removeIconSrc} alt={'${loeschen}'} width='21' />
            </Translate>
          </a>
        </li>
      ) : null
  }

  getFreeAction (isFree) {
    return this.props.possibleStatus !== undefined &&
    this.props.possibleStatus.indexOf('frei') !== -1
      ? (
        <a onClick={() => this.props.toggleFreiModal(this.props.konfigurationId, this.props.name)}>
          <Translate id='konfigurationen/aktion/frei-setzen' data={{ 'frei': 'auf frei setzen', 'unlockIconSrc': unlockIconSrc }}>
            <img style={{ marginBottom: '5px' }} title={'${frei}'} src={unlockIconSrc} alt={'${frei}'} width='20' />
          </Translate>
        </a>
      ) : null
  }

  getVermietetAction (isVermietet) {
    return this.props.possibleStatus !== undefined &&
      this.props.possibleStatus.indexOf('vermietet') !== -1
      ? (
        <a onClick={() => this.props.toggleVermietetModal(this.props.konfigurationId, this.props.name)}>
          <Translate id='konfigurationen/aktion/vermietet-setzen' data={{ 'vermietet': 'auf vermietet setzen', 'handshakeIconSrc': handshakeIconSrc }}>
            <img style={{ marginBottom: '5px' }} title={'${vermietet}'} src={handshakeIconSrc} alt={'${vermietet}'} width='28' />
          </Translate>
        </a>
      ) : null
  }

  getMakler (free, vermietet) {
    return this.props.isMakler
      ? {
        column: {
          nameFirst: <td>{this.props.nameFirst}</td>,
          nameLast: <td>{this.props.nameLast}</td>
        },
        action: <span>
          {free}
          {vermietet}
        </span>
      }
      : { column: { nameFirst: null, nameLast: null }, action: null }
  }

  getStatus () {
    if (this.props.ownStatus) {
      return this.props.status + ' (diese Konfiguration)'
    }
    if (this.props.status === 'teilweise_vermietet') {
      return 'vermietet (in anderer Kombination)'
    }

    return this.props.status
  }

  render () {
    const isFree = this.isFree()
    const isVermietet = this.isVermietet()
    const editAction = this.getEditAction(isVermietet)
    const copyAction = this.getCopyAction(isFree, isVermietet)
    const downloadAction = this.getDownloadAction(this.props.report)
    const removeAction = this.getRemoveAction(isFree)
    const status = this.getStatus()
    const makler = this.getMakler(this.getFreeAction(isFree), this.getVermietetAction(isVermietet))

    return (
      <tr className='bk-configuration-row'>
        <td>{this.props.konfigurationId}</td>
        <td>
          <strong><KonfigurationName konfigurationId={this.props.konfigurationId} name={this.props.name} /></strong>
        </td>
        {makler.column.nameFirst}
        {makler.column.nameLast}
        <td>{this.props.wohnungsCode.replace(new RegExp(' - ', 'g'), ', ').toUpperCase()}</td>
        <td><Translate id={'konfigurationen/status/' + this.props.status}>{status}</Translate></td>
        <td className='numeric'>
          <ul>
            {makler.action}
            {editAction}
            {copyAction}
            {downloadAction}
            {removeAction}
          </ul>
        </td>
      </tr>
    )
  }
}
