import React from 'react'
import Translate from '../../../lib/App/common/translator/components/translate'

const LabelWithInfoButton = props => (
  <label className='label' htmlFor={props.for}>{props.optionDesc}
    <Tooltip toolTip={props.optionTooltip} />
  </label>
)

export const InfoButton = props => {
  return <span>{props.children}{props.optionDesc}
    <Tooltip toolTip={props.optionTooltip}
      tooltipToLeft={props.tooltipToLeft} />
  </span>
}

export const Tooltip = props => {
  let classes = props.classes ? props.classes : 'bk-btn-info'

  if (props.tooltipToLeft === true) {
    classes = classes + ' bk-btn-info-mobile-left'
  }

  const translateTooltip = props.translateId ? <Translate id={props.translateId}>{props.toolTip}</Translate> : props.toolTip

  return (
    <span className={classes} style={props.outerStyle}>i
      <span>
        <span style={props.innerStyle}>{translateTooltip}</span>
      </span>
    </span>
  )
}

export default LabelWithInfoButton
