import React, { Fragment } from 'react'
import { CurrencyFormat } from '../../../../lib/App/common/ValueFormatter'
import Translate from '../../../../lib/App/common/translator/components/translate'

const KonfiguratorHighlights = props => {
  const konfigurationV = props.konfigurationV || {}

  const translateTotal = <Translate id='KonfiguratorHighlights/total' data={{ 'm2': 'm<sup>2</sup>' }}>{'Total ${ m2 }/Jahr Netto'}</Translate>
  const translateMietzins = <Translate id='KonfiguratorHighlights/mietzins'>Jahresmietzins Netto</Translate>
  const translateEigen = <Translate id='KonfiguratorHighlights/eigentuemerschaft'>Kostenübernahme Eigentümerschaft</Translate>

  return (
    <Fragment>
      <tr>
        <td>{translateTotal}</td>
        <td><CurrencyFormat value={konfigurationV.totalQmJahrPreis} currency='CHF' /></td>
      </tr>
      <tr>
        <td>{translateMietzins}</td>
        <td><CurrencyFormat value={konfigurationV.jahresMietZins} currency='CHF' /></td>
      </tr>
    </Fragment>
  )
}

export default KonfiguratorHighlights
