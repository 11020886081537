import React, { Fragment } from 'react'
import Translate from '../../lib/App/common/translator/components/translate'

export const NumberVariation = props => {
  const name = props.name !== undefined ? props.name : ''

  const aufpreisBeschreibung = props.aufpreisBeschreibung !== undefined
    ? <p className='costs-additional'><Translate id={'optionen/' + props.code + '/aufpreisBeschreibung'}>{props.aufpreisBeschreibung}</Translate></p> : null

  const translateName = <Translate id={'optionen/' + props.code + '/name'}>{name}</Translate>
  const translateUnit = <Translate id={'optionen/' + props.code + '/einheit'}>{props.unit}</Translate>

  return (
    <Fragment>
      <h3>{translateName}: <strong>{props.value} {translateUnit}</strong></h3>
      {aufpreisBeschreibung}
    </Fragment>
  )
}

export const TextVariation = props => {
  const translateTitle = props.value ? <Translate id={'variationen/' + props.code + '/name'}>{props.value}</Translate> : '-'
  return <h3 style={{ whiteSpace: 'pre-line' }}>{translateTitle}</h3>
}
