import React from 'react'

const Error = props => {
  return (
    <section className='group bk-prop-selected'>
      <div className='group row'>
        <div className='group columns large-12 medium-12 small-12' style={{ textAlign: 'center' }}>
          <h1 style={{ textAlign: 'center' }}><i className='fas fa-exclamation-triangle' /> Fehler</h1>
          <p>Bitte kontaktieren Sie den Webadministrator.</p>
        </div>
      </div>
    </section>
  )
}

export default Error
