import React from 'react'
import SearchGroupOption from '../SearchGroupOption'
import SearchGroupLabel from '../SearchGroupLabel'
import Columns from '../Columns'
import SearchGroupUnorderedList, { SearchGroupListEntry } from '../SearchGroupList'
import LabelWithInfoButton from '../../../../../App/common/InfoButton/InfoButton'
import Translate from '../../../../../lib/App/common/translator/components/translate'

const SearchRooms = props => {
  const sitzungszimmer = props.sitzungszimmerVariationen.map(zimmer => {
    const checked = props.sitzungszimmer ? props.sitzungszimmer === zimmer.variationCode : zimmer.isDefault
    const translateZimmer = <Translate id={'variationen/' + zimmer.variationCode + '/name'}>{zimmer.name}</Translate>
    return (
      <SearchGroupListEntry key={`bk-search-rooms-conf-${zimmer.name}`}>
        <label className='label' htmlFor={`bk-search-rooms-conf-${zimmer.name}`}>
          <input
            type='radio'
            name='bk_search_rooms_conf'
            id={`bk-search-rooms-conf-${zimmer.name}`}
            value={zimmer.variationCode}
            checked={checked}
            onChange={(event) => { props.sitzungszimmerChanged(event.target.value) }}
          />
          <span><span className='radio-check' />{translateZimmer}</span>
        </label>
      </SearchGroupListEntry>)
  })

  const translateRaeumeName = <Translate id={'optionen/sitzungszimmer/name'}>{props.sitzungszimmerName}</Translate>
  const translateRaeumeTip = <Translate id={'variationen/sz-intern/tooltip'}>{'Im Gebäude steht ein Konferenzcenter mit diversen Sitzungszimmern zur Verfügung. Ob für ein kurzes Meeting ab einer Stunde oder auch längerfristig - diese Flächen sind je Bedarf buchbar und müssen nicht in die Mietfläche integriert werden. Somit können im Geschoss Flächen eingespart, bzw effizienter genutzt werden und stehen für Arbeitsplätze zur Verfügung.'}</Translate>

  return (
    <SearchGroupOption optionClass='bk-search-group-option-rooms'>

      <SearchGroupLabel title={'Zusätzliche Räume'} desc={''} translateId={props.translateId} />

      <Columns large={7} medium={7} small={12}>
        <SearchGroupUnorderedList>
          <SearchGroupListEntry>
            <LabelWithInfoButton
              for={'bk-search-rooms-conf-int'}
              optionDesc={translateRaeumeName}
              optionTooltip={translateRaeumeTip}
            />
            <SearchGroupUnorderedList>
              {sitzungszimmer}
            </SearchGroupUnorderedList>
          </SearchGroupListEntry>
        </SearchGroupUnorderedList>
      </Columns>
    </SearchGroupOption>
  )
}

export default SearchRooms
