import React, { Fragment } from 'react'
import { TreeableKategorie } from '../../../../lib/App/common/konfigurator/compontents/Kategorien'
import OptionenSwitch, { Option } from './Optionen'

class KategorieLeonardo extends TreeableKategorie {
  render () {
    const subKategorien = this.getSubKategorien(this.props)
    const optionen = <Optionen {...this.props} />
    return this.props.isSubset
      ? <SubsetKategorie {...this.props} kategorien={subKategorien} optionen={optionen} />
      : <RootKategorie {...this.props} kategorien={subKategorien} optionen={optionen} />
  }
}

export default KategorieLeonardo

const Optionen = props => {
  const { optionen } = props

  if (optionen === undefined || optionen.length === 0) {
    return null
  }

  const ownOptionComponent = Option
  return <OptionenSwitch {...props} ownOptionComponent={ownOptionComponent} firstOption={optionen[0]} />
}

const RootKategorie = props => {
  return (
    <Fragment>
      {props.kategorien}
      {props.optionen}
    </Fragment>
  )
}

const SubsetKategorie = props => {
  return (
    <Fragment>
      {props.kategorien}
      {props.optionen}
    </Fragment>
  )
}
