import React, { Fragment } from 'react'
import Translate from '../../../../lib/App/common/translator/components/translate'

import VariationenSwitch from './Variationen'
import { Option } from './Optionen'
import RenderingConex from '../Rendering'

const PackageVariationKategorieLeonardo = props => {
  const firstOption = props.optionen[0] || {}
  const otherOptions = props.optionen.slice(1)
  const OptionComponent = props.switches.optionenSwitch(firstOption, props.components, Option)
  const hasRendering = (props.classes.indexOf('rendering') !== -1 && props.rendering !== undefined)
  const rendering = hasRendering ? (
    <div className='group columns large-8 medium-7 small-12'>
      <RenderingConex imagePath={props.rendering.imagePath} name={props.name} />
    </div>
  ) : null

  const topBorderStyle = hasRendering ? { borderTop: 'none', paddingTop: 0 } : null

  const firstComponent = (<OptionComponent
    {...firstOption}
    kategorieId={props.kategorieId}
    switches={props.switches}
    components={props.components}
    isReadOnly={props.isReadOnly}
    classes={props.classes}
    handleVariationChange={props.handleVariationChange}
    handleFrontendPropertyChange={props.handleFrontendPropertyChange}
    style={topBorderStyle}
  />)

  const flexStyle = hasRendering ? { 'display': 'flex' } : {}
  return (
    <Fragment>
      <div className='group columns large-12 medium-12 small-12' style={flexStyle}>
        { firstOption.classes.indexOf('above_rendering') !== -1 ? firstComponent : null }
        {rendering}
        <div className='columns large-4 medium-5 small-12'>
          { firstOption.classes.indexOf('above_rendering') === -1 ? firstComponent : null }
          {otherOptions.map(option => option ? (
            <div className='bk-options-thumbs group columns large-12 medium-12 small-12' key={option.optionId}>
              <div className='columns large-12 medium-12 small-12'>
                <h2><Translate id={'optionen/' + option.optionCode + '/name'}>{option.name}</Translate></h2>
                <p>{props.beschreibung ? <Translate id={'optionen/' + option.optionCode + '/beschreibung'}>{option.beschreibung}</Translate> : ''}</p>
                <VariationenSwitch {...props} {...option} components={props.components} switches={props.switches}
                  handleVariationChange={props.handleVariationChange} isReadOnly={props.isReadOnly} />
              </div>
            </div>
          ) : null)}
        </div>
      </div>
    </Fragment>
  )
}

export default PackageVariationKategorieLeonardo
