import React, { Fragment } from 'react'
import Optionen from './Optionen'
import { NumberVariation, TextVariation } from './Variationen'
import Translate from '../../lib/App/common/translator/components/translate'

const Kategorien = props => {
  const kategorien = props.kategorien || []
  return kategorien.map(kategorie => {
    return kategorenSwitch(kategorie)
  })
}

export default Kategorien

function kategorenSwitch (kategorie) {
  if (~kategorie.classes.indexOf('emphasized')) {
    return <PackageKategorieEmphasized key={kategorie.kategorieId} {...kategorie} />
  } else if (~kategorie.classes.indexOf('package_variation')) {
    return <PackageVariationKategorie key={kategorie.kategorieId} {...kategorie} />
  } else if (~kategorie.classes.indexOf('package')) {
    return <PackageKategorie key={kategorie.kategorieId} {...kategorie} />
  } else {
    return <Kategorie key={kategorie.kategorieId} {...kategorie} />
  }
}

const PackageKategorieEmphasized = props => {
  const options = props.optionen || []
  const firstOption = options[0]
  const variationen = firstOption.variationen

  const translateOptionName = <Translate id={'optionen/' + firstOption.optionCode + '/name'}>{firstOption.name}</Translate>
  const translateOptionDesc = firstOption.beschreibung ? <Translate id={'optionen/' + firstOption.optionCode + '/beschreibung'}>{firstOption.beschreibung}</Translate> : ''

  return (
    <div className='bk-options-summary group columns large-12 medium-12 small-12'>
      <div className='columns large-6 medium-6 small-12'>
        <h2>{translateOptionName}</h2>
        <p>{translateOptionDesc}</p>
      </div>
      <div className='columns large-6 medium-6 small-12'>
        <ul>
          {variationen.map((variation) => {
            const translateVariationName = <Translate id={'variationen/' + variation.variationCode + '/name'}>{variation.name}</Translate>
            const translateVariationDesc = variation.aufpreisBeschreibung ? <Translate id={'variationen/' + variation.variationCode + '/aufpreisBeschreibung'}>{variation.aufpreisBeschreibung}</Translate> : ''

            return (
              <li>
                <h3>{translateVariationName}</h3>
                <span className='costs-additional'>{translateVariationDesc}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

const PackageVariationKategorie = props => {
  const firstOption = props.optionen[0] || {}
  const otherOptions = props.optionen.slice(1)
  const clearStyle = { clear: 'both' }
  return (
    <Fragment>
      <Optionen optionen={[firstOption]} />
      <Optionen optionen={otherOptions} />
      <div style={clearStyle} />
    </Fragment>
  )
}

const PackageKategorie = props => {
  const options = props.optionen || []
  const translateName = <Translate id={'kategorie/' + props.kategorieCode + '/name'}>{props.name}</Translate>
  const translateDesc = props.beschreibung ? <Translate id={'kategorie/' + props.kategorieCode + '/beschreibung'}>{props.beschreibung}</Translate> : ''

  return (
    <div className='bk-options-summary group columns large-12 medium-12 small-12'>
      <div className='columns large-6 medium-6 small-12'>
        <h2>{translateName}</h2>
        <p>{translateDesc}</p>
      </div>
      <div className='columns large-6 medium-6 small-12'>
        <ul>
          {options.map(option => packageSwitch(option, props))}
        </ul>
      </div>
    </div>
  )
}

function packageSwitch (option, props) {
  if (~option.classes.indexOf('text')) {
    const text = option.text || {}
    return <li key={option.optionId}><TextVariation {...text} /></li>
  } else if (~option.classes.indexOf('number')) {
    const number = option.number || {}
    return <li key={option.optionId}><NumberVariation {...number} name={option.name} code={option.optionCode} /></li>
  } else if (~option.classes.indexOf('boolean')) {
    const variation = option.variationen[0] || {}
    const variationName = variation.name === '1' ? 'Ja'
      : variation.name === '0' ? 'Nein' : variation.name
    const translateName = <Translate id={'variationen/' + variation.variationCode + '/name'}>{variationName}</Translate>
    const translateDesc = variation.beschreibung ? <Translate id={'variationen/' + variation.variationCode + '/beschreibung'}>{variation.beschreibung}</Translate> : ''
    return (
      <li key={option.optionId}>
        <h3>{translateName}</h3>
        <p>{translateDesc}</p>
      </li>
    )
  } else if (~option.classes.indexOf('info')) {
    const variation = option.variationen[0] || {}
    let variationName = variation.name ? variation.name : ''

    const numbers = variation.name.match(/\d+/g).map(Number)
    let replaces = {}
    numbers.forEach((elem, index) => {
      replaces['num' + index] = elem
    })
    Object.keys(replaces).forEach((key) => {
      variationName = variationName.replace(replaces[key], '${' + key + '}')
    })
    const translateVName = variationName ? <Translate id={'variationen/' + variation.variationCode + '/name'} data={replaces}>{variationName}</Translate> : ''
    return <li key={option.optionId}><h3>{translateVName}</h3></li>
  }
}

const Kategorie = props => {
  return (
    <Fragment>
      <Kategorien kategorien={props.kategorien} />
      <Optionen optionen={props.optionen} />
    </Fragment>
  )
}
